import React, { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import DashboardLayout from "../App/Components/Layout/DashboardLayout";
import { Spin } from "antd";
import PublicRoutes from "../App/utils/publicRoutes";
import Login from "../App/Pages/Login/Login";
import PrivateRoute from "../App/utils/PrivateRoute";
import User from "../App/Pages/Users/user";

const AllRoutes = () => {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  const dashboardRoutes = [
    {
      path: "/",
      exact: true,
      Component: lazy(() => import("../App/Pages/Dashboard/Dashboard")),
      // roles: [1, 2],
    },
    {
      path: "/dashboard",
      exact: true,
      Component: lazy(() => import("../App/Pages/Dashboard/Dashboard")),
      // roles: [1, 2],
    },
    {
      path: "/schools",
      exact: true,
      Component: lazy(() => import("../App/Pages/Schools/Schools")),
      // roles: [1, 2],
    },
    {
      path: "state/:id",
      exact: true,
      Component: lazy(() => import("../App/Pages/City/City")),
      // roles: [1, 2],
    },
    {
      path: "/add-school",
      exact: true,
      Component: lazy(() => import("../App/Pages/Forms/Forms")),
      // roles: [1, 2],
    },
    {
      path: "/form/:id",
      exact: true,
      Component: lazy(() => import("../App/Pages/Forms/Forms")),
      // roles: [1, 2],
    },
    {
      path: "/user-management",
      exact: true,
      Component: lazy(() => import("../App/Pages/Users/user")),
      // roles: [1, 2],
    },
    {
      path: "city/locality/:id",
      exact: true,
      Component: lazy(() => import("../App/Pages/Locality/Locality")),
      // roles: [1, 2],
    },
    {
      path: "/blogs",
      exact: true,
      Component: lazy(() => import("../App/Pages/Blogs/index")),
      // roles: [1, 2],
    },
    {
      path: "/blogs-author",
      exact: true,
      Component: lazy(() => import("../App/Pages/Blogs/BlogsAuthor/index")),
      // roles: [1, 2],
    },
    {
      path: "/blogs-category",
      exact: true,
      Component: lazy(() => import("../App/Pages/Blogs/BlogsCategory/index")),
      // roles: [1, 2],
    },
    {
      path: "/blogs-setting",
      exact: true,
      Component: lazy(() => import("../App/Pages/Blogs/BlogSettings/index")),
      // roles: [1, 2],
    },
    {
      path: "/seo",
      exact: true,
      Component: lazy(() => import("../App/Pages/SEO/Seo")),
    },
    {
      path: "/add-seo",
      exact: true,
      Component: lazy(() => import("../App/Pages/SEO/AddSeo")),
    },
    {
      path: "/edit-seo/:id",
      exact: true,
      Component: lazy(() => import("../App/Pages/SEO/AddSeo")),
    },
    {
      path: "/dynamic-faq",
      exact: true,
      Component: lazy(() => import("../App/Pages/DynamicFaqs")),
    },
    {
      path: "/requested-call",
      exact: true,
      Component: lazy(() => import("../App/Pages/RequestCall")),
    },
    {
      path: "/state",
      excat: true,
      Component: lazy(() => import("../App/Pages/State")),
    },
    {
      path: "/enquiry-details",
      excat: true,
      Component: lazy(() => import("../App/Pages/EnquiryDetails")),
    },
    {
      path: "/school-personalization-details",
      excat: true,
      Component: lazy(
        () => import("../App/Pages/SchoolPersonalizationDetails")
      ),
    },
    {
      path: "/child-age-eligibility-details",
      excat: true,
      Component: lazy(() => import("../App/Pages/ChildAgeEligibiltyDetails")),
    },
    {
      path: "/upload-file",
      exact: true,
      Component: lazy(() => import("../App/Pages/UploadFile")),
      roles: [1, 2, 3],
    },
  ];

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);
  let dashboardLinks = dashboardRoutes?.map((each) => each?.path);

  return (
    <>
      {/* <Routes>
   
      </Routes> */}
      <DashboardLayout
        visible={
          dashboardLinks?.includes(window.location.pathname) ||
          window.location.pathname?.includes("/form") ||
          window.location.pathname?.includes("/edit-seo") ||
          window.location.pathname?.includes("/city/locality/") ||
          window.location.pathname?.includes("state")
        }
      >
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route excat path="login" element={<Login />} />
          </Route>
          <Route>
            {dashboardRoutes.map(({ path, Component, exact }, index) => (
              <Route
                path={path}
                key={path}
                exact={exact}
                element={
                  <Suspense fallback={<Spin />}>
                    <PrivateRoute>
                      <Component />
                    </PrivateRoute>
                  </Suspense>
                }
              />
            ))}
          </Route>
        </Routes>
      </DashboardLayout>
    </>
  );
};

export default AllRoutes;
